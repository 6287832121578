import { createHead } from "@vueuse/head";

import App from "./App.vue";
import { createPinia } from "./modules/pinia";
import { createRouter } from "./modules/router";
import { createUrql } from "./modules/urql";

import "the-new-css-reset/css/reset.css";

const app = createApp(App);
app.config.performance = !!import.meta.env.DEV;

const head = createHead();
app.use(head);

createPinia(app);
createUrql(app);

createRouter(app);

app.mount("#app");
