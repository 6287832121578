import { RouterOptions, createRouter as _createRouter, createWebHistory } from "vue-router/auto";
import type { App } from "vue";

// import generatedPages from "virtual:generated-pages";
// import { setupLayouts } from "virtual:generated-layouts";

// declare module "vue-router" {
// 	interface RouteMeta {
// 		title?: string;
// 	}
// }

const routerOptions: Partial<RouterOptions> = {
	// scrollBehavior: (to, _from, savedPosition) => {
	// 	const behavior = savedPosition ? savedPosition : to.hash ? { selector: to.hash } : { top: 0, behavior: "smooth" };
	// 	return { ...behavior, behavior: "smooth" };
	// },
	scrollBehavior(to, from, savedPosition) {
		if (to.hash) {
			return new Promise((resolve, _reject) => {
				setTimeout(() => {
					resolve({ el: to.hash });
				}, 300);
			});
		}
		if (savedPosition) {
			return savedPosition;
		}
		if (to.meta.noScroll && from.meta.noScroll) {
			return {};
		}
		return { top: 0 };
	},
};

// async function setDocumentTitle(to: RouteLocationNormalized) {
// 	const parent = to.matched.find((record) => record.meta.title);
// 	const parentTitle = parent ? parent.meta.title : null;
// 	document.title = to.meta.title || parentTitle || "web";
// }

export function createRouter(app: App) {
	const router = _createRouter({
		...routerOptions,
		history: createWebHistory(),
	});

	// router.afterEach(setDocumentTitle);

	app.use(router);

	return router;
}
