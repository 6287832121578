<script setup lang="ts">
import { provideDebug, provideQuiz } from "./utils";
provideDebug();
provideQuiz();
</script>

<template>
	<router-view />
</template>

<style lang="scss">
html,
body {
	padding: 0;
	margin: 0;
	width: 100%;
	height: 100%;
	color: var(--oslo-color-green-dark);
	background-color: var(--oslo-color-green-light);

	* {
		box-sizing: border-box;
	}
}

a {
	text-decoration: none;
	color: unset;
}

#app {
	height: 100%;
}
</style>
