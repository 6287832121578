import { createPinia as _createPinia } from "pinia";
import createPiniaPersist from "pinia-plugin-persist";
import type { App } from "vue";

export function createPinia(app: App) {
	const pinia = _createPinia();
	pinia.use(createPiniaPersist);

	app.use(pinia);

	return pinia;
}
