import urql, { Client, fetchExchange } from "@urql/vue";
// import { cacheExchange } from "@urql/exchange-graphcache";
import type { App } from "vue";

// import { schema } from "@navlearn/gql";

let client: Client;

export function createUrql(app: App) {
	const url = "/graphql";
	client = new Client({
		url,
		exchanges: [
			// cacheExchange({
			// 	keys: {
			// 		multiple_choice_image_options: () => null,
			// 		multiple_choice_text_options: () => null,
			// 		multiple_choice_multiple_answer_options: () => null,
			// 	},
			// 	// schema,
			// }),
			fetchExchange,
		],
	});

	app.use(urql, client);
}
