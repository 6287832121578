export const routes = [
  {
    path: '/',
    name: '/',
    component: () => import('/build/packages/web/src/pages/index.vue'),
    /* no children */
  },
  {
    path: '/questions',
    /* internal name: '/questions' */
    /* no component */
    children: [
      {
        path: ':questionOrder',
        name: '/questions/[questionOrder]',
        component: () => import('/build/packages/web/src/pages/questions/[questionOrder].vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/results',
    name: '/results',
    component: () => import('/build/packages/web/src/pages/results.vue'),
    /* no children */
  }
]
