import { GetQuizDocument, GetQuizQuery } from "@navlearn/gql";
import { useQuery } from "@urql/vue";
import { InjectionKey } from "vue";

export type Quiz = GetQuizQuery["quiz"][0];
const PROVIDE_QUIZ_INJECTION_KEY = Symbol() as InjectionKey<Ref<Quiz | undefined>>;

export function provideQuiz() {
	const { data, error, fetching } = useQuery({
		query: GetQuizDocument,
		variables: { filter: {} },
	});

	const quiz: Ref<Quiz | undefined> = computed(() => data.value?.quiz.at(0));

	provide(PROVIDE_QUIZ_INJECTION_KEY, quiz);

	return { fetching, quiz, error };
}

export function useQuiz() {
	const quiz = inject(PROVIDE_QUIZ_INJECTION_KEY);

	// const quiz = computed(() => data.value?.quiz.at(0));
	const questions = computed(() => quiz?.value?.questions);

	return { quiz, questions };
}
