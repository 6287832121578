import { defineStore } from "pinia";

type BaseAnswer = { correctFactor: number; isCorrect: boolean };
export type ClickableImageAnswer = BaseAnswer & { x: number; y: number };
export type DragAndDropAnswer = BaseAnswer & { order: string[] };
export type MultipleChoiceAnswer = BaseAnswer & { id: string };
export type MultipleChoiceImageAnswer = MultipleChoiceAnswer;
export type MultipleChoiceTextAnswer = MultipleChoiceAnswer;
export type MultipleChoiceMultipleAnswer = BaseAnswer & { answers: MultipleChoiceAnswer[] };
export type TextAnswer = BaseAnswer & { text: string };
export type NavTimetrackingAnswer = BaseAnswer & { answers: { [key: string]: string } };

export type QuestionAnswer =
	| ClickableImageAnswer
	| DragAndDropAnswer
	| MultipleChoiceImageAnswer
	| MultipleChoiceTextAnswer
	| MultipleChoiceMultipleAnswer
	| TextAnswer;

export const useProgressStore = defineStore("progress", {
	persist: {
		enabled: true,
		strategies: [{ key: "navlearn-progress", storage: localStorage }],
	},
	state: () => ({
		answers: {} as { [questionId: string]: QuestionAnswer | undefined },
		currentQuestionIndex: 0,
	}),
	getters: {
		progress: (state) => Object.keys(state.answers).length,
		hasProgress(): boolean {
			return this.progress > 0;
		},
		isFirstQuestion: (state) => state.currentQuestionIndex === 0,
		questionAnswer:
			(state) =>
			<T extends QuestionAnswer = QuestionAnswer>(questionId: string) =>
				state.answers[questionId] as T,
	},
	actions: {
		submitAnswer(questionId: string, answer: QuestionAnswer) {
			// if (this.answers[questionId]) throw new Error("Question already answered");
			this.answers[questionId] = answer;
		},
		nextQuestion() {
			this.currentQuestionIndex++;
		},
		previousQuestion() {
			this.currentQuestionIndex--;
		},
		reset() {
			this.answers = {};
			this.currentQuestionIndex = 0;
		},
	},
});

export type ProgressStore = ReturnType<typeof useProgressStore>;
