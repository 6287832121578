let isInitiated = false;
const isDebugMode = ref(false);

export function provideDebug() {
	if (isInitiated) return;

	const route = useRoute();

	watch(
		route,
		() => {
			if ("debug" in route.query) isDebugMode.value = true;
		},
		{ immediate: true },
	);

	isInitiated = true;
}

export function useDebug() {
	return { isDebugMode };
}
